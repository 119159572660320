import React, { useContext } from "react"
import { graphql } from "gatsby"

import Seo from "./../components/Layout/Seo"
import Collection from "./../components/Collection/List"
import InstagramFeed from "../components/Instagram/InstagramFeed"

import { DictionaryContext } from "./../contexts/dictionary"
import ComparisonChart from "../components/Collection/ComparisonChart"
import TitleComponent from "../components/Title"
import styled from "styled-components"

const Wrapper = styled.section`
    padding: 6rem 0;

    ${props => props.theme.above.desktop`
        padding: 12rem 0;
    `}
`

const InnerWrapper = styled.div`
    width: 100%;
    max-width: 100rem;

    margin: 0 auto;
    padding: 0 3rem;

    ${props => props.theme.above.desktop`
        padding: 0;
    `}
`

const Content = styled.div`
    p,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 3rem !important;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    strong,
    b {
        display: block;

        color: #fff;

        margin-bottom: -2rem;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: ${props => props.theme.fontFamily.circularMedium};
        font-size: 1.75rem;
        line-height: 2.25rem;
        /* line-height: 2.5rem; */
    }

    ${props => props.theme.above.desktop`
        column-count: 2;
        column-gap: 5rem;
    `}
`

const Footer = styled.div`
    width: 100%;
    max-width: 80rem;

    margin-top: 3rem;

    text-align: left;

    p,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 3rem !important;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    strong,
    b {
        display: block;

        font-weight: 600;
        color: #fff;

        margin-bottom: -2rem;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: ${props => props.theme.fontFamily.circularMedium};
        font-size: 1.75rem;
        line-height: 2.25rem;
        /* line-height: 2.5rem; */
    }

    ${props => props.theme.above.desktop`
        margin: 12rem auto 0 auto;
        
        text-align: center;
    `}
`

const CollectionPage = ({
    pageContext: { lang, langSlug },
    data: {
        instagram,
        products,
        collection: {
            data: {
                collection_title,
                collection_description,
                collection_navigation,
                collection_media,
                comparison_title,
                comparison_description,
                comparison_button_label,
                comparison_download,
                comparison_chart_desktop,
                comparison_chart_mobile,
                about_title,
                about_subtitle,
                about_content,
                about_footer,
                sizes,
                colors,
                seo_title,
                seo_description,
                seo_keywords,
                seo_image,
            },
        },
    },
}) => {
    const dictionary = useContext(DictionaryContext)

    const comparisonData = {
        title: comparison_title,
        description: comparison_description,
        button: comparison_button_label,
        download: comparison_download,
        images: {
            desktop: comparison_chart_desktop,
            mobile: comparison_chart_mobile,
        },
    }

    return (
        <>
            <Seo
                title={seo_title}
                description={seo_description && seo_description.text}
                keywords={seo_keywords}
                image={seo_image}
            />
            <Collection
                lang={langSlug}
                title={collection_title}
                description={collection_description}
                items={products.nodes}
                type="collection"
                navigation={collection_navigation}
                filters={[
                    {
                        type: "size",
                        placeholder: dictionary.all_sizes,
                        options: sizes,
                    },
                    {
                        type: "color",
                        placeholder: dictionary.all_colors,
                        options: colors,
                    },
                ]}
                media={collection_media}
            />
            <ComparisonChart data={comparisonData} />
            {about_title.text != "" && (
                <Wrapper>
                    <TitleComponent
                        title={about_title.text}
                        description={about_subtitle.raw}
                        alignment="center"
                    />
                    <InnerWrapper>
                        <Content
                            dangerouslySetInnerHTML={{
                                __html: about_content.html,
                            }}
                        ></Content>
                        {about_footer?.raw[0]?.text != "" && (
                            <Footer
                                dangerouslySetInnerHTML={{
                                    __html: about_footer.html,
                                }}
                            ></Footer>
                        )}
                    </InnerWrapper>
                </Wrapper>
            )}
            {/* <InstagramFeed 
                copy={instagram} 
            /> */}
        </>
    )
}

export const QUERY_COLLECTIONPAGE = graphql`
    query CollectionPage($id: String!, $lang: String!) {
        collection: prismicCollectionPage(id: { eq: $id }) {
            id
            lang
            ...CollectionPageQuery
        }
        products: allPrismicProductItem(
            sort: { order: ASC, fields: data___product_title___text }
            filter: {
                data: { product_type: { ne: "Classics" } }
                lang: { eq: $lang }
            }
        ) {
            nodes {
                uid
                data {
                    product_title {
                        text
                    }
                    product_awards_group {
                        product_award
                    }
                    product_family {
                        slug
                        document {
                            ... on PrismicProductFamily {
                                data {
                                    title {
                                        text
                                    }
                                }
                            }
                        }
                    }
                    product_size {
                        document {
                            ... on PrismicProductSize {
                                data {
                                    title {
                                        text
                                    }
                                }
                            }
                        }
                    }
                    small: thumbnail {
                        alt
                        dimensions {
                            width
                            height
                        }
                        fluid(maxWidth: 200) {
                            ...GatsbyPrismicImageFluid
                        }
                    }
                    medium: thumbnail {
                        alt
                        dimensions {
                            width
                            height
                        }
                        fluid(maxWidth: 400) {
                            ...GatsbyPrismicImageFluid
                        }
                    }
                    body {
                        ... on PrismicProductItemBodyColors {
                            primary {
                                color {
                                    document {
                                        ... on PrismicProductColor {
                                            data {
                                                title {
                                                    text
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            items {
                                color
                                month
                            }
                        }
                    }
                }
            }
        }
        instagram: prismicInstagramComponent(lang: { eq: $lang }) {
            id
            lang
            ...InstagramQuery
        }
    }
`

export default CollectionPage
