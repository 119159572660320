import React from "react"
import { useInView, InView } from "react-intersection-observer"
import styled from "styled-components"
import Img from "gatsby-image"

import Title from "./../Title"
import ButtonCircle from "../Buttons/ButtonCircle"

const Wrapper = styled.section`
    position: relative;

    padding: 8rem 0;

    background-color: #252525;

    ${props => props.theme.below.desktop`
        .title-wrapper {
            padding: 0;
        }
    `}

    ${props => props.theme.above.desktop`
        padding: 20rem 0;
    `}
`

const TitleWithButtonWrapper = styled.div`
    text-align: center;

    .title-wrapper {
        margin-bottom: 2.5rem;
    }

    .button-circle {
        display: inline-block;
    }

    ${props => props.theme.below.desktop`
        padding: 0 3rem;

        text-align: left;

        .button-circle {
            padding: 0 0 3rem 0;
        }
    `}

    ${props => props.theme.above.desktop`
        margin-bottom: 15rem;
    `}
`

const StyledButtonCircle = styled(ButtonCircle)`
    ${props => props.theme.below.desktop`
        display: none !important;
    `}
`

const ChartWrapper = styled.div`
    padding: 0 3rem;

    ${props => props.theme.above.desktop`
        padding: 0;
    `}
`

const Chart = styled.div`
    width: 100%;
    max-width: 100rem;
    margin: 0 auto;

    ${props => props.theme.below.desktop`
        padding: 6rem;
        padding-bottom: 4rem;
        background-color: #313030;
    `}
`

const MobileImage = styled(Img)`
    ${props => props.theme.below.desktop`
        display: block;
    `}

    ${props => props.theme.above.desktop`
        display: none;
    `}
`

const DesktopImage = styled(Img)`
    ${props => props.theme.below.desktop`
        display: none;
    `}

    ${props => props.theme.above.desktop`
        display: block;
    `}
`

const Link = styled.a`
    display: block;
    margin-top: 3rem;

    text-align: center;

    font-family: ${props => props.theme.fontFamily.circularBook};
    font-size: 1.4rem;
    line-height: 2.5rem;

    ${props => props.theme.above.desktop`
        display: none;
    `}
`

const DownloadTitle = styled.p`
    color: #fff;
`

const DownloadLabel = styled.p``

const ComparisonChart = props => {
    const {
        data: {
            title,
            description,
            button,
            download,
            images: { desktop, mobile },
        },
    } = props

    const [ref, wrapperInView] = useInView({
        threshold: 0.15,
        triggerOnce: true,
    })

    return (
        <Wrapper ref={ref}>
            <InView>
                <TitleWithButtonWrapper>
                    <Title
                        title={title.text}
                        description={description.text}
                        alignment="center"
                        text
                    />
                    <StyledButtonCircle
                        type="arrow"
                        direction="down"
                        label={button}
                        animation="after"
                        href={download.url}
                        target="_blank"
                    />
                </TitleWithButtonWrapper>
                <ChartWrapper>
                    <Chart>
                        <MobileImage fluid={mobile.fluid} alt={mobile.alt} />
                        <DesktopImage fluid={desktop.fluid} alt={desktop.alt} />
                        <Link href={download.url} target="_blank">
                            <DownloadTitle>{title.text}</DownloadTitle>
                            <DownloadLabel>{button}</DownloadLabel>
                        </Link>
                    </Chart>
                </ChartWrapper>
            </InView>
        </Wrapper>
    )
}

export default ComparisonChart
